var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{staticClass:"pa-1",attrs:{"app":"","fixed":"","flat":"","clipped-left":"","color":"primary"}},[(!_vm.search)?_c('v-toolbar-title',{staticClass:"mr-3"},[_c('v-img',{attrs:{"src":require("../../assets/logo.svg")}})],1):_vm._e(),(
        (_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.lgAndUp) && !_vm.search
      )?_c('v-spacer'):_vm._e(),(_vm.$vuetify.breakpoint.smAndUp || _vm.search)?[_c('base-text-field',{attrs:{"dense":"","dark":"","label":"Busque por algo"},model:{value:(_vm.form.search),callback:function ($$v) {_vm.$set(_vm.form, "search", $$v)},expression:"form.search"}})]:_vm._e(),(_vm.$vuetify.breakpoint.xsOnly)?[_c('base-btn-icon',{attrs:{"icon":!_vm.search ? 'magnify' : 'close'},on:{"action":_vm.toggleSearch}})]:_vm._e(),(_vm.$vuetify.breakpoint.lgAndUp)?_c('v-spacer'):_vm._e(),(_vm.$vuetify.breakpoint.mdAndDown)?[_c('v-app-bar-nav-icon',{staticClass:"pl-2"},[_c('base-btn-icon',{attrs:{"icon":!_vm.drawer ? 'menu' : 'close'},on:{"action":_vm.toggleDrawer}})],1)]:[_c('v-list',{staticClass:"pa-0",attrs:{"flat":"","color":"primary"}},[_c('profile-avatar',{attrs:{"user":_vm.loggedUser}})],1)]],2),_c('v-navigation-drawer',{style:(!_vm.$vuetify.breakpoint.lgAndUp
        ? ("margin-top: " + (_vm.$vuetify.application.top + 1) + "px; margin-right: 10px; border-radius: 8px;")
        : 'background-color: #051d3b'),attrs:{"right":!_vm.$vuetify.breakpoint.lgAndUp,"color":"rgba(45, 65, 91, 1)","clipped":"","dark":"","hide-overlay":"","app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{staticClass:"mt-3",attrs:{"nav":"","dense":"","flat":""}},[(_vm.$vuetify.breakpoint.lgAndUp)?_c('v-subheader',[_vm._v("Menu")]):_vm._e(),_c('v-list-item-group',{attrs:{"value":_vm.selectedItem}},_vm._l((_vm.drawerRouters),function(item,i){return _c('v-list-item',{key:i,staticClass:"mb-4",attrs:{"to":{ name: item.name }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var active = ref.active;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var hover = ref.hover;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-sheet',{staticClass:"d-flex align-center justify-center",attrs:{"color":active
                      ? 'rgba(80, 129, 251, 1)'
                      : hover
                      ? 'rgba(80, 129, 251, 0.64)'
                      : 'rgba(80, 129, 251, 0.16)',"width":"48","height":"48","rounded":"lg"}},[_c('v-icon',[_vm._v("mdi-"+_vm._s(item.meta.icon))])],1),_c('v-list-item-content',{staticClass:"menu pl-5"},[_c('v-list-item-title',{style:(active ? '' : hover ? 'opacity: 72%;' : 'opacity: 56%;'),domProps:{"textContent":_vm._s(item.meta.title)}})],1)],1)]}}],null,true)})]}}],null,true)})}),1),(_vm.$vuetify.breakpoint.mdAndDown)?[_c('v-divider',{staticClass:"mx-4"}),_c('v-list-item',{staticClass:"mt-4"},[_c('profile-avatar',{attrs:{"user":_vm.loggedUser}})],1)]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }